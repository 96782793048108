@import url('https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap');

.chatPage {
    font-family: 'Roboto Mono', monospace;
    font-size: 1.8rem;
    margin: auto;
    padding: 1.5em;
    width: 60%;
    background-color: white;
    min-height: 20em;
}

.all-messages p {
    display: block;
    word-wrap: break-word;
    margin: 0.5em;
    padding: 0.5em;
    /* padding-left: 0.2em; */
    border: 1px solid lightblue;
    border-radius: 20px;
    /* width: 95%; */
}

.all-messages {
    width: 100%;
    height: 30vh;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 2rem;
    overflow: auto;
    /* padding: 0.5em; */
}

.all-messages p:nth-child(2n) {
    background-color: lightblue;
    /* width: 100%; */
}

.new-message {
    width: 100%;
    min-height: 5vh;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 2rem;
    font-size: 1.8rem;
}

@media screen and (max-width: 700px) {
    .chatPage {
        width: inherit;
        padding: 1em;
        margin: 0;
        font-size: 1rem;
    }
    .new-message {
        font-size: 1rem;
    }
}
