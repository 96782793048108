@import url(https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.info {
    display: block;
    width: 50%;
    margin: 0 auto;
    background-color: white;
    padding: 1.5em;
    min-height: 40em;
}

.me {
    display: block;
    border: 1px solid #999;
    padding: 0.5em;
    margin: 1em;
    width: 300px;
    float: right;
}

@media screen and (max-width: 700px) {
    .me {
        float: none;
        width: 100%;
        margin: 0;
        margin-bottom: 1.4rem;
        border: 0;
        padding: 0;
    }

    .info {
        width: inherit;
        padding: 1em;
        margin: 0;
    }
}

.loginPage {
    width: 50%;
    margin: 0 auto;
    background-color: white;
    padding: 1.5em;
    min-height: 40em;
}

@media screen and (max-width: 700px) {
    .loginPage {
        width: inherit;
        padding: 1em;
        margin: 0;
    }
}

.button {
  color: #333;
  background-color: #fff;
  background-image: linear-gradient(#fff, #cccccc);
  border: 1px solid #cccccc;
  text-decoration: none;
  box-sizing: border-box;
  display: inline-block;
  font-family: "Open sans", sans-serif;
  font-size: 1.4rem;
  padding: 0.4rem 1rem;
  text-align: center;
  border-radius: 0.5rem;
  line-height: 1.4;
  margin-bottom: 1.4rem;
  box-shadow: 0px 3px 5px 0px #999;
  width: 70%;
}

.createButton {
  color: #333;
  background-color: #fff;
  background-image: linear-gradient(#fff, #cccccc);
  border: 1px solid #cccccc;
  text-decoration: none;
  box-sizing: border-box;
  display: inline-block;
  font-family: "Open sans", sans-serif;
  font-size: 1rem;
  padding: 0.2rem 0.2rem;
  text-align: center;
  border-radius: 0.2rem;
  line-height: 1.4;
  margin-bottom: 1.4rem;
  box-shadow: 0px 3px 5px 0px #999;
}

.editButton {
    width: 50%;
    margin: 1em auto;
}

.button:hover {
  background-color: #b3b3b3;
  background-image: none;
}

.button:active {
  background-color: #a6a6a6;
  background-image: none;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15);
}

.full-width-button {
width: 100%;
}

.blue-button {
  color: #fff;
  background-color: #0074d9;
  background-image: linear-gradient(#0074d9, #003d73);
  border-color: #003d73;
}

.blue-button:hover {
  background-color: #002240;
  background-image: none;
}

.blue-button:active {
  background-color: #001527;
  background-image: none;
}

.green-button {
  color: #fff;
  background-color: #2ecc40;
  background-image: linear-gradient(#2ecc40, #1b7926);
  border-color: #1b7926;
}

.green-button:hover {
  background-color: #124f19;
  background-image: none;
}

.green-button:active {
  background-color: #0d3a12;
  background-image: none;
}

.reportPage {
    border: 1px solid #ccc;
    padding: 0.5em;
}

.input {
  font-size: 1.4rem;
  line-height: 1.4;
  font-family: "Roboto", sans-serif;
  display: block;
  border: 1px solid #ccc;
  border-radius: 0.2rem;
  padding: 0.6rem 0.6rem;
  box-sizing: border-box;
  width: 70%;
  margin-bottom: 1.4rem;
  resize: none;
}

.input-textarea {
  font-size: 1rem;
  line-height: 1.4;
  font-family: "Roboto", sans-serif;
  display: block;
  border: 1px solid #ccc;
  border-radius: 0.2rem;
  padding: 0.6rem 0.6rem;
  box-sizing: border-box;
  width: 70%;
  min-height: 20em;
  margin-bottom: 1.4rem;
  resize: vertical;
}

.input-label {
  font-weight: bold;
  margin: 2rem 0 0 0;
  display: block;
}

.input-label:first-of-type {
  margin: 1rem 0 0 0;
}

.input-label::after {
  content: ":";
}

.input:valid {
    border: 1px solid green;
}

.input:focus {
    box-shadow: 0 0 7px 2px #0091ea;
}

.gdpr {
    display: inline;
    font-size: 0.8rem;
    margin-left: 1rem;
    margin-bottom: 1.4rem;
}

.box {
    transform: scale(1.5);
}

.text {
    font-size: 1rem;
}

@media (max-width: 667px) {
  .input {
    width: 100%;
  }
}

.registerPage {
    width: 50%;
    margin: 0 auto;
    background-color: white;
    padding: 1.5em;
    min-height: 40em;
}


@media (max-width: 667px) {
  .registerPage {
    width: inherit;
    padding: 1em;
  }
}

.reports {
    width: 50%;
    margin: 0 auto;
    background-color: white;
    padding: 1.5em;
    min-height: 40em;
}

/* .reports ul {
    padding: 0.5em;
} */

.reports a {
    text-decoration: none;
}

.reports a:hover {
    text-decoration: underline;
}

.weekNav {
    padding: 0;
}

.weekNav li {
    display: inline-block;
    padding-right: 0.5em;
}

.weekNav li a {
    color: blue;
    text-decoration: none;
    /* border-left: 1px solid #000;
    border-right: 1px solid #000;
    border-top: 1px solid #000;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;  */
}

.weekNav li a:hover {
    color: #555;
    text-decoration: underline;
}

.reports h1 {
    margin-bottom: 0.5em;
}

@media screen and (max-width: 700px) {
    .reports {
        width: inherit;
        padding: 1em;
        margin: 0;
    }
}

.createPage {
    width: 50%;
    margin: 0 auto;
    background-color: white;
    padding: 1.5em;
    min-height: 40em;
}

@media screen and (max-width: 700px) {
    .createPage {
        width: inherit;
        padding: 1em;
        margin: 0;
    }
}

.chatPage {
    font-family: 'Roboto Mono', monospace;
    font-size: 1.8rem;
    margin: auto;
    padding: 1.5em;
    width: 60%;
    background-color: white;
    min-height: 20em;
}

.all-messages p {
    display: block;
    word-wrap: break-word;
    margin: 0.5em;
    padding: 0.5em;
    /* padding-left: 0.2em; */
    border: 1px solid lightblue;
    border-radius: 20px;
    /* width: 95%; */
}

.all-messages {
    width: 100%;
    height: 30vh;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 2rem;
    overflow: auto;
    /* padding: 0.5em; */
}

.all-messages p:nth-child(2n) {
    background-color: lightblue;
    /* width: 100%; */
}

.new-message {
    width: 100%;
    min-height: 5vh;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 2rem;
    font-size: 1.8rem;
}

@media screen and (max-width: 700px) {
    .chatPage {
        width: inherit;
        padding: 1em;
        margin: 0;
        font-size: 1rem;
    }
    .new-message {
        font-size: 1rem;
    }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.NavBar {
    background-color: #2e8b57;
    display: flex;
    justify-content: space-between;
}

.NavBar ul {
    display: flex;
    flex-direction: row;
    text-decoration: none;
}

.NavBar li {
    display: block;
    margin-right: 1em;
    text-decoration: none;
}

.NavBar a {
    text-decoration: none;
    color: white;
    font-size: 1.4rem;
    margin-bottom: 0;
}

.NavBar a:hover {
    text-decoration: underline;
}

html {
    margin: 0;
    padding: 0;
    overflow-y: scroll;
    height: 100%;
    width: 100%;
    color: #333;
    font-size: 100%;
    background-color: #333;
}

body {
    height: 100%;
    width: 100%;
    line-height: 1.4;
}

main {
    padding: 1.4rem 0.6rem;
}

h1,
h2,
h3,
h4 {
    margin-top: 0;
}

h1 {
    font-size: 2.4rem;
    margin-bottom: 3.36rem;
}

h2 {
    font-size: 2rem;
    margin-bottom: 1.68rem;
}

h4 {
    font-size: 1.4rem;
    margin-bottom: 0;
}

code {
    padding: 0.2em;
    /* margin-top: 0.5em;
    margin-bottom: 0.5em;  */
    border-radius: 5px;
    background-color: #eee;
}

.title {
    font-size: 2.4rem;
    margin-bottom: 2.8rem;
    border-bottom: 1px solid #ccc;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #eee;
  /* min-height: 60em; */
  display: flex;
  flex-direction: column;
  color: #333;
}

.logo {
    width: 60px;
    margin: 0.5em;
}

.pageFooter {
    bottom: 0;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    background-color: #333;
    color: #fff;
    min-height: 5em;
}

@media screen and (max-width: 700px) {
    .NavBar a {
        font-size: 1rem;
    }
}

