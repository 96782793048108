.reports {
    width: 50%;
    margin: 0 auto;
    background-color: white;
    padding: 1.5em;
    min-height: 40em;
}

/* .reports ul {
    padding: 0.5em;
} */

.reports a {
    text-decoration: none;
}

.reports a:hover {
    text-decoration: underline;
}

.weekNav {
    padding: 0;
}

.weekNav li {
    display: inline-block;
    padding-right: 0.5em;
}

.weekNav li a {
    color: blue;
    text-decoration: none;
    /* border-left: 1px solid #000;
    border-right: 1px solid #000;
    border-top: 1px solid #000;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;  */
}

.weekNav li a:hover {
    color: #555;
    text-decoration: underline;
}

.reports h1 {
    margin-bottom: 0.5em;
}

@media screen and (max-width: 700px) {
    .reports {
        width: inherit;
        padding: 1em;
        margin: 0;
    }
}
