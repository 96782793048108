.createPage {
    width: 50%;
    margin: 0 auto;
    background-color: white;
    padding: 1.5em;
    min-height: 40em;
}

@media screen and (max-width: 700px) {
    .createPage {
        width: inherit;
        padding: 1em;
        margin: 0;
    }
}
