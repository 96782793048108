.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.NavBar {
    background-color: #2e8b57;
    display: flex;
    justify-content: space-between;
}

.NavBar ul {
    display: flex;
    flex-direction: row;
    text-decoration: none;
}

.NavBar li {
    display: block;
    margin-right: 1em;
    text-decoration: none;
}

.NavBar a {
    text-decoration: none;
    color: white;
    font-size: 1.4rem;
    margin-bottom: 0;
}

.NavBar a:hover {
    text-decoration: underline;
}

html {
    margin: 0;
    padding: 0;
    overflow-y: scroll;
    height: 100%;
    width: 100%;
    color: #333;
    font-size: 100%;
    background-color: #333;
}

body {
    height: 100%;
    width: 100%;
    line-height: 1.4;
}

main {
    padding: 1.4rem 0.6rem;
}

h1,
h2,
h3,
h4 {
    margin-top: 0;
}

h1 {
    font-size: 2.4rem;
    margin-bottom: 3.36rem;
}

h2 {
    font-size: 2rem;
    margin-bottom: 1.68rem;
}

h4 {
    font-size: 1.4rem;
    margin-bottom: 0;
}

code {
    padding: 0.2em;
    /* margin-top: 0.5em;
    margin-bottom: 0.5em;  */
    border-radius: 5px;
    background-color: #eee;
}

.title {
    font-size: 2.4rem;
    margin-bottom: 2.8rem;
    border-bottom: 1px solid #ccc;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #eee;
  /* min-height: 60em; */
  display: flex;
  flex-direction: column;
  color: #333;
}

.logo {
    width: 60px;
    margin: 0.5em;
}

.pageFooter {
    bottom: 0;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    background-color: #333;
    color: #fff;
    min-height: 5em;
}

@media screen and (max-width: 700px) {
    .NavBar a {
        font-size: 1rem;
    }
}
