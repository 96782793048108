.info {
    display: block;
    width: 50%;
    margin: 0 auto;
    background-color: white;
    padding: 1.5em;
    min-height: 40em;
}

.me {
    display: block;
    border: 1px solid #999;
    padding: 0.5em;
    margin: 1em;
    width: 300px;
    float: right;
}

@media screen and (max-width: 700px) {
    .me {
        float: none;
        width: 100%;
        margin: 0;
        margin-bottom: 1.4rem;
        border: 0;
        padding: 0;
    }

    .info {
        width: inherit;
        padding: 1em;
        margin: 0;
    }
}
